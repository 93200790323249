export const casinoTabs = {
  roulettetab: {
    UltimateRoulette: {
      href: "/casino/evolution/UltimateRoulette",
      code: "541000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Ultimate Roulette",
      cover: { src: "./images/roulette/ultimate-roulette.jpg", alt: "" }
    },
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed Roulette",
      cover: { src: "./images/roulette/speed-roulette.png", alt: "" }
    },
    DiamondRoulette: {
      href: "/casino/ezugi/DiamondRoulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Diamond Roulette",
      cover: { src: "./images/roulette/diamond-roulette.png", alt: "" }
    },
    NamasteRoulette: {
      href: "/casino/ezugi/NamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Namaste Roulette",
      cover: { src: "./images/roulette/mamaste-roulette.png", alt: "" }
    },
    DoubleballRoulette: {
      href: "/casino/ezugi/DoubleballRoulette",
      code: "1000084",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Double Ball Roulette",
      cover: { src: "./images/roulette/double-ball-roulette.jpg", alt: "" }
    },
    InstantRoulette: {
      href: "/casino/ezugi/InstantRoulette",
      code: "1000146",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Instant Roulette",
      cover: { src: "./images/roulette/InstantRoulette.jpg", alt: "" }
    },
    AutoRoulette: {
      href: "/casino/ezugi/AutoRoulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Auto Roulette",
      cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" }
    },
    SpeedAutoRoulette: {
      href: "/casino/ezugi/SpeedAutoRoulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed Auto Roulette",
      cover: { src: "./images/roulette/speed-auto-roulette.png", alt: "" }
    },
    OracleRoulette2: {
      href: "/casino/ezugi/OracleRoulette2",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Oracle Roulette 2",
      cover: { src: "./images/roulette/oracle-roulette-2.png", alt: "" }
    },
    LightningRoulette: {
      href: "/casino/ezugi/LightningRoulette",
      code: "1000092",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Lightning Roulette",
      cover: { src: "./images/roulette/Lightning-roulette.jpg", alt: "" }
    },
    SalonPriveRoulette: {
      href: "/casino/ezugi/SalonPriveRoulette",
      code: "1000102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Salon Prive Roulette",
      cover: { src: "./images/roulette/salon-prive-roulette.jpg", alt: "" }
    },
    ItalianRoulette: {
      href: "/casino/ezugi/ItalianRoulette",
      code: "1000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Italian Roulette",
      cover: { src: "./images/roulette/itailianRoulette.jpg", alt: "" }
    },
    SpanishRoulette: {
      href: "/casino/ezugi/SpanishRoulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Spanish Roulette",
      cover: { src: "./images/roulette/spanish-roulette.jpg", alt: "" }
    },
    RussianRoulette: {
      href: "/casino/ezugi/RussianRoulette",
      code: "601000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Russian Roulette",
      cover: { src: "./images/roulette/Russianroulette.jpg", alt: "" }
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Prestige Auto Roulette",
      cover: { src: "./images/roulette/prestige-auto-oulette.jpg", alt: "" }
    },
    HandCasinoHoldem: {
      href: "/casino/evolution/HandCasinoHoldem",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Hand Casino Holdem",
      cover: { src: "./images/roulette/2handholdem.jpg", alt: "" }
    }
  },
  baccarattab: {
    Baccaratpro: {
      href: "/casino/ezugi/Baccaratpro",
      code: "100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Baccarat Pro",
      cover: { src: "./images/baccarat/baccarat01.png", alt: "" }
    },
    Nocommissiobaccarat: {
      href: "/casino/ezugi/Nocommissiobaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "No Commission Baccarat",
      cover: { src: "./images/baccarat/baccarat02.png", alt: "" }
    },
    Knockoutbaccarat: {
      href: "/casino/ezugi/Knockoutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Knockout Baccarat",
      cover: { src: "./images/baccarat/baccarat03.png", alt: "" }
    },
    Super6baccarat: {
      href: "/casino/ezg-super-6-baccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Super 6 Baccarat",
      cover: { src: "./images/baccarat/baccarat04.png", alt: "" }
    },
    LightningBaccarat: {
      href: "/casino/ezugi/LightningBaccarat",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Lightning Baccarat",
      cover: { src: "./images/baccarat/Lightning-Baccarat.jpg", alt: "" }
    },
    NoCommissionBaccarat: {
      href: "/casino/ezugi/NoCommissionBaccarat",
      code: "1000018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "No Commission Baccarat",
      cover: { src: "./images/baccarat/No-Commission-Baccarat.jpg", alt: "" }
    },
    Marinabaccarat: {
      href: "/casino/ezg-casino-marina-baccarat-1",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Marina Baccarat",
      cover: { src: "./images/baccarat/baccarat05.png", alt: "" }
    },
    Marina02baccarat: {
      href: "/casino/ezugi/Marina02baccarat",
      code: "32101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Marina 02 Baccarat",
      cover: { src: "./images/baccarat/baccarat06.png", alt: "" }
    },
    Salsabaccarat: {
      href: "/casino/ezg-speed-cricket-baccarat",
      code: "41100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Salsa Baccarat",
      cover: { src: "./images/baccarat/SpeedCricketBaccarat.jpg", alt: "" }
    },
    Baccarat: {
      href: "/casino/ezugi/Baccarat",
      code: "106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Baccarat",
      cover: { src: "./images/baccarat/FortuneBaccarat.jpg", alt: "" }
    },
    SpeedBaccarat: {
      href: "/casino/ezugi/SpeedBaccarat",
      code: "105",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed Baccarat",
      cover: { src: "./images/baccarat/SpeedFortuneBaccarat.jpg", alt: "" }
    },
    Marina03baccarat: {
      href: "/casino/ezugi/Marina03baccarat",
      code: "32103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Marina 03 Baccarat",
      cover: { src: "./images/baccarat/CasinoMarinaBaccarat4.jpg", alt: "" }
    },
    SpeedbaccaratA: {
      href: "/casino/ezugi/SpeedbaccaratA",
      code: "1000021",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed Baccarat A",
      cover: { src: "./images/baccarat/Speed-Baccarat-A.jpg", alt: "" }
    },
    SpeedbaccaratB: {
      href: "/casino/ezugi/SpeedbaccaratB",
      code: "1000022",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed Baccarat B",
      cover: { src: "./images/baccarat/Speed-Baccarat-B.jpg", alt: "" }
    },
    SpeedbaccaratH: {
      href: "/casino/ezugi/SpeedbaccaratH",
      code: "1000028",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Speed Baccarat H",
      cover: { src: "./images/baccarat/Speed-Baccarat-H.jpg", alt: "" }
    }
  },
  AndarBahartab: {
    // AndarBaharVivo: {
    //   href: "/casino/vivo-andar-bahar",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "Vivo",
    //   homeUrl: "",
    //   cover: {
    //     src: "./images/andarbahar/VivoAndarBahar.jpg",
    //     alt: "vivo andar bahar",
    //   },
    // },
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Andar Bahar Live",
      cover: { src: "./images/andarbahar/AndarBahar01.png", alt: "" }
    },
    AndarBaharOTT: {
      href: "/casino/ezugi/AndarBaharOTT",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Andar Bahar OTT",
      cover: { src: "./images/andarbahar/OttAndarBahar.jpg", alt: "" }
    },
    UltimateAndarBahar: {
      href: "/casino/ezugi/UltimateAndarBahar",
      code: "228100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Ultimate Andar Bahar",
      cover: { src: "./images/andarbahar/UltimateAndarBahar.jpg", alt: "" }
    },
    SuperAndarBahar: {
      href: "/casino/qtechevolutiongaming-super-andar-bahar",
      code: "",
      casino: "qtech",
      provider: "Qtech",
      homeUrl: "",
      name: "Super Andar Bahar",
      cover: {
        src: "./images/andarbahar/SuperAndarBahar.jpg",
        alt: ""
      }
    }
  },
  TeenPattitab: {
    // TeenPattiVivo: {
    //   href: "/casino/vivo-teen-patti",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "Vivo",
    //   homeUrl: "",
    //   name: "Vivo Teen Patti",
    //   cover: {
    //     src: "./images/poker/VivoTeenPatti.jpg",
    //     alt: "vivo teen patti",
    //   },
    // },
    TeenPatti01: {
      href: "/casino/ezugi/TeenPatti01",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Teen Patti 01",
      cover: { src: "./images/poker/teenpattithreecards.jpg", alt: "" }
    },
    TeenPatti03: {
      href: "/casino/ezugi/TeenPatti03",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Teen Patti 03",
      cover: { src: "./images/poker/Poker03.png", alt: "" }
    },
    TeenPatti04: {
      href: "/casino/ezugi/TeenPatti04",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Teen Patti 04",
      cover: { src: "./images/poker/Poker04.png", alt: "" }
    },
    HandCasinoHoldem: {
      href: "/casino/ezugi/HandCasinoHoldem",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Hand Casino Holdem",
      cover: { src: "./images/poker/2-Hand-Casino-Hold'em.jpg", alt: "" }
    },
    UltimateCasinoHoldem: {
      href: "/casino/ezugi/UltimateCasinoHoldem",
      code: "1000151",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Ultimate Casino Holdem",
      cover: { src: "./images/poker/Ultimate-Texas-Hold'em.jpg", alt: "" }
    },
    TeenPatti05: {
      href: "/casino/ezgevo-side-bet-city",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Teen Patti 05",
      cover: { src: "./images/poker/Poker05.png", alt: "" }
    },
    TexasHoldem3CardPoker: {
      href: "/casino/ezugi/TexasHoldem3CardPoker",
      code: "1000111",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Texas Holdem 3 Card Poker",
      cover: { src: "./images/poker/Texas-Hold'em-Bonus-Poker.jpg", alt: "" }
    },
    TeenPatti07: {
      href: "/casino/ezg-casino-holdem",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Teen Patti 07",
      cover: { src: "./images/poker/Poker07.png", alt: "" }
    },
    TeenPatti08: {
      href: "/casino/ezugi/TeenPatti08",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Teen Patti 08",
      cover: { src: "./images/poker/Poker08.png", alt: "" }
    },
    DragonTger: {
      href: "/casino/ezugi/DragonTger",
      code: "1000074",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      name: "Dragon Tiger",
      cover: { src: "./images/poker/Dragon-Tiger.jpg", alt: "" }
    },
    TeenPattiFaceOff: {
      href: "/casino/sn-teen-patti-face-off",
      code: "",
      casino: "supernowa",
      provider: "SN",
      name: "Teen Patti Face Off",
      cover: { src: "./images/poker/teenpattifaceoff1.jpg", alt: "" }
    }
  },
  Blackjacktab: {
    Blackjack: {
      name: "Blackjack",
      href: "/casino/ezugi/Blackjack",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack01.png", alt: "" }
    },
    GoldBlackjack: {
      name: "Gold Blackjack",
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/GOldBlackjack5.jpg", alt: "" }
    },
    BlackjackPro: {
      name: "Blackjack Pro",
      href: "/casino/ezugi/BlackjackPro",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack019.png", alt: "" }
    },
    VIPBlackjack: {
      name: "VIP Blackjack",
      href: "/casino/ezugi/VIPBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/VIPDimondBlackjack.jpg", alt: "" }
    },
    RuletadelSol: {
      name: "Ruleta del Sol",
      href: "/casino/ezg-ruleta-del-sol",
      code: "1000066",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/ezg-ruleta-del-sol.jpg", alt: "" }
    },
    InfiniteBlackjack: {
      name: "Infinite Blackjack",
      href: "/casino/ezugi/InfiniteBlackjack",
      code: "1000067",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Infinite-Blackjack.jpg", alt: "" }
    },
    Craps: {
      name: "Craps",
      href: "/casino/ezugi/Craps",
      code: "1000248",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Craps.jpg", alt: "" }
    },
    TurkceBlackjack: {
      name: "Türkçe Blackjack",
      href: "/casino/ez-turkce-blackjack-2",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/ez-turkce-blackjack-2.jpg", alt: "" }
    },
    LiveBlackjack: {
      name: "Live Blackjack",
      href: "/casino/ezgevo-blackjack-party",
      code: "221",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/ezgevo-blackjack-party.jpg", alt: "" }
    },
    LightningBlackjack: {
      name: "Lightning Blackjack",
      href: "/casino/ezugi/LightningBlackjack",
      code: "1000358",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Lightning-Blackjack.jpg", alt: "" }
    },
    BlackjackVIPO: {
      name: "Blackjack VIP O",
      href: "/casino/ezugi/BlackjackVIPO",
      code: "1000058",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack-VIP-O.jpg", alt: "" }
    },
    BlackjackVIPR: {
      name: "Blackjack VIP R",
      href: "/casino/ezugi/BlackjackVIPR",
      code: "1000060",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack-VIP-R.jpg", alt: "" }
    },
    BlackjackVIPS: {
      name: "Blackjack VIP S",
      href: "/casino/ezugi/BlackjackVIPS",
      code: "1000061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack-VIP-S.jpg", alt: "" }
    },
    BlackjackLive: {
      name: "Blackjack Live",
      href: "/casino/ezgevo-blackjack-silver-a",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/blackjack/ezgevo-blackjack-silver-a.jpg",
        alt: ""
      }
    },
    PlatinumBlackjack1: {
      name: "Platinum Blackjack 1",
      href: "/casino/ezugi/PlatinumBlackjack1",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack019.png", alt: "" }
    }
  },

  thirtytwoCardtab: {
    thirtytwoCards: {
      name: "32 Cards",
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/32cards01.png", alt: "" }
    }
  },
  sevenLuckytab: {
    sevenLucky: {
      name: "Lucky 7",
      href: "/casino/ezugi/sevenLucky",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/Lucky01.png", alt: "" }
    }
  },
  sicbotab: {
    sicbo: {
      name: "Sic Bo",
      href: "/casino/ezugi/sicbo",
      code: "224000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice02.png", alt: "" }
    },
    SuperSicBo: {
      name: "Super Sic bo",
      href: "/casino/ezugi/SuperSicBo",
      code: "1000110",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Super-Sic-Bo.jpg", alt: "" }
    }
  },
  GameShows: {
    CrazyTime: {
      name: "Crazy Time",
      href: "/casino/evolution/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/other/Crazy-time.jpg", alt: "" }
    },
    FootballStudio: {
      name: "Football Studio",
      href: "/casino/ezugi/FootballStudio",
      code: "1000112",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/other/Football-studio.jpg", alt: "" }
    },
    DreamCatcher: {
      name: "Dream Catcher",
      href: "/casino/ezugi/DreamCatcher",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/other/Dram-catcher.jpg", alt: "" }
    },
    CashorCrash: {
      name: "Cash or Crash",
      href: "/casino/ezugi/CashorCrash",
      code: "1000352",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/other/Cash-or-crash.jpg", alt: "" }
    }
  },

  Providers: {
    evolution: {
      name: "Evolution",
      code: "",
      casino: "ezugi",
      href: "/casino/evolution",
      provider: "EVO_LOBBY",
      homeUrl: "",
      cover: { src: "./images/provider/evolution.jpg", alt: "evolution" }
    },
    ezugi: {
      name: "Ezugi",
      href: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/provider/Ezugi.jpg", alt: "ezugi" }
    },
    qtech: {
      name: "QTech",
      href: "/casino/qtech",
      code: "",
      casino: "qtech",
      provider: "QT",
      homeUrl: "",
      cover: { src: "./images/provider/Qtech.jpg", alt: "qtech" }
    },
    slots: {
      name: "Slots",
      href: "/Slots",
      code: "",
      casino: "qtech",
      provider: "QT",
      homeUrl: "",
      cover: { src: "./images/provider/Slots.jpg", alt: "slots" }
    },
    kingmaker: {
      name: "Kingmaker",
      href: "/kingmaker",
      code: "",
      casino: "qtech",
      provider: "QT",
      homeUrl: "",
      cover: { src: "./images/provider/kingmaker.jpg", alt: "kingmaker" }
    },
    betby: {
      name: "Sports",
      href: "/betby",
      code: "",
      casino: "qtech",
      provider: "QT",
      homeUrl: "",
      cover: { src: "./images/provider/Betby.jpg", alt: "sports" }
    },
    exchange: {
      name: "Exchange",
      href: "/sports/cricket",
      code: "",
      casino: "qtech",
      provider: "QT",
      homeUrl: "",
      cover: { src: "./images/provider/exchange.jpg", alt: "exchange" }
    }
  },

  spribe: {
    mines: {
      name: "Mines",
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "",
      cover: { src: "./images/spribe/Mine.jpg", alt: "" }
    },
    goal: {
      name: "Goal",
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "",
      cover: { src: "./images/spribe/Goal.jpg", alt: "" }
    },
    dice: {
      name: "Dice",
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "",
      cover: { src: "./images/spribe/Dice.jpg", alt: "" }
    },
    aviator: {
      name: "Aviator",
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "",
      cover: { src: "./images/spribe/Aviator.jpg", alt: "" }
    },
    plinko: {
      name: "Plinko",
      href: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "",
      cover: { src: "./images/spribe/Plinko.jpg", alt: "" }
    },
    miniroulette: {
      name: "Mini Roulette",
      href: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "",
      cover: { src: "./images/spribe/MiniRoulette.jpg", alt: "" }
    },
    hilo: {
      name: "Hi-Lo",
      href: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "",
      cover: { src: "./images/spribe/Hilo.jpg", alt: "" }
    }
  }
};
