import React from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";

import { casinoTabs } from "../../lib/data";

const GamesSearchModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [filteredData, setFilteredData] = useState(null);
  const [searchText, setSearchText] = useState(false);
  const totalGames = {
    ...casinoTabs?.roulettetab,
    ...casinoTabs?.baccarattab,
    ...casinoTabs?.Blackjacktab,
    ...casinoTabs?.AndarBahartab,
    ...casinoTabs?.GameShows,
    ...casinoTabs?.sevenLuckytab,
    ...casinoTabs?.spribe,
    ...casinoTabs?.TeenPattitab,
    ...casinoTabs?.sicbotab,
    ...casinoTabs?.Providers,

    // ...casinoTabs.roulettetab,
  };

  const handleSearch = (e) => {
    if (e) {
      setSearchText(true);

      let filteredValue = Object.values(totalGames)?.filter((item) => {
        return (
          item.name && item.name.toLowerCase().indexOf(e?.toLowerCase()) > -1
        );
      });
      setFilteredData(filteredValue);
    } else {
      setSearchText(false);
    }
  };
  console.log("dataaaa", filteredData);

  return (
    <>
      <div className="searchGames" onClick={handleShow}>
        <BiSearch style={{ fontSize: "18px" }} />
        Games
      </div>

      <Modal show={show} onHide={handleClose} className="searchGames1">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#000" }}>Search For Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <InputGroup className="mb-3 search-icon">
            <Form.Control
              placeholder="Search"
              aria-label="Text input with dropdown button"
              type="search"
              onChange={(e) => handleSearch(e.target.value)}
              style={{ background: "#000" }}
              autoFocus
            />
          </InputGroup>
          <div className="GamesShow">
            {filteredData &&
              filteredData.map((item) => (
                <div className="displayGames">
                  <a href={item.redirectUrl || item.href}>
                    {item.imgUrl ? (
                      <img src={item.imgUrl} />
                    ) : (
                      <img src={item?.cover?.src} />
                    )}
                  </a>
                </div>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GamesSearchModal;
