import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/images/logo_.png";
import numberWithCommas from "../utils/numbersWithComma";
import WalletCard from "../views/components/WalletCard";

import HomeIconNew from "../assets/images/icons/building.png";
import CasinoIconNew from "../assets/images/icons/poker-cards.png";
import EzugiIconNew from "../assets/images/icons/roulette.png";
import EvolutionIconNew from "../assets/images/icons/playing-cards.png";
import SportsIconNew from "../assets/images/icons/football.png";
import WalletIconNew from "../assets/images/icons/digital-wallet.png";
import AviatorIconNew from "../assets/images/icons/airplane-mode.png";
import QtechIcon from "../assets/images/icons/qtech.png";
import SlotsIcon from "../assets/images/icons/slots.png";

import ProfileIconNew from "../assets/images/icons/user.png";
import RedderalIconNew from "../assets/images/icons/referral.png";
import SignOutIconNew from "../assets/images/icons/logout.png";
import StatementIconNew from "../assets/images/icons/financial-statement.png";
import BonusIconNew from "../assets/images/icons/gift-card.png";
import MyBetsIconNew from "../assets/images/icons/casino-chip.png";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
import GamesSearchModal from "../views/components/GamesSearchModal";

const HeaderAfterLogin = () => {
  const { user, wallet } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const headerPages = [
    "home",
    "/sports/Inplay",
    "/games-all",
    "/casino/ezugi",
    "/casino/evolution",
    "/casino/spribe/aviator",
    "/cashier",
    "/Slots",
  ];

  useEffect(() => {
    loadNotifications();
  }, []);

  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };
  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <div className="logo">
                  <a href="/home">
                    <img src={appDetails?.LOGO_URL || logo} alt="" />
                  </a>
                </div>
                <nav className="mainNav">
                  <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
                    <li className="home">
                      <a
                        href="/home"
                        className={activePage == "home" ? "active" : ""}
                      >
                        <img src={HomeIconNew} alt="home" />
                        <span>Home</span>
                      </a>
                    </li>
                    <li className="home">
                      <a
                        href="/sports/Inplay"
                        className={
                          activePage == "/sports/Inplay" ? "active" : ""
                        }
                      >
                        <img src={SportsIconNew} alt="sports" />
                        <span>Sports</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/games-all"
                        className={activePage == "/games-all" ? "active" : ""}
                      >
                        <img src={CasinoIconNew} alt="casino" />
                        <span>Casino</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/casino/ezugi"
                        className={
                          activePage == "/casino/ezugi" ? "active" : ""
                        }
                      >
                        <img src={EzugiIconNew} alt="ezugi" />
                        <span>Ezugi</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/casino/evolution"
                        className={
                          activePage == "/casino/evolution" ? "active" : ""
                        }
                      >
                        <img src={EvolutionIconNew} alt="evolution" />
                        <span>Evolution</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/casino/spribe/aviator"
                        className={
                          activePage == "/casino/spribe/aviator" ? "active" : ""
                        }
                      >
                        <img src={AviatorIconNew} alt="aviator" />
                        <span>Aviator</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/casino/qtech"
                        className={activePage == "/qtech" ? "active" : ""}
                      >
                        <img src={QtechIcon} alt="qtech" />
                        <span>Qtech</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/Slots"
                        className={activePage == "/Slots" ? "active" : ""}
                      >
                        <img src={SlotsIcon} alt="slots" />
                        <span>Slots</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/cashier"
                        className={activePage == "/cashier" ? "active" : ""}
                      >
                        <img src={WalletIconNew} alt="wallet" />
                        <span>Wallet</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className={activePage == "/cashier" ? "active" : ""}
                        // style={{ display: "none" }}
                      >
                        <span>
                          <GamesSearchModal />
                        </span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="headerRight">
                  <WalletCard wallet={wallet} />
                  <div
                    className="Deposit_btn ms-2"
                    onClick={() => {
                      navigate("/gatewaylist");
                    }}
                  >
                    <Button variant="primary w-100">Deposit</Button>
                  </div>

                  <Button variant=" menu-btn" onClick={handleShow}>
                    <MenuIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
          <Offcanvas.Header closeButton="closeButton" closeVariant="white">
            <Offcanvas.Title>
              <div className="d-flex">
                <div className="profile-img">
                  <ProfileIcon />
                </div>
                <div className="user-info">
                  <span>{user?.mstruserid}</span>
                  <p>{user?.mobileno}</p>
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="col-6">
                <div className="balance mb-3">
                  <WalletIcon />
                  Balance
                </div>
              </div>
              <div className="col-6">
                <div
                  className="balance-amount mb-4"
                  onClick={() => {
                    // setshowtestmodal((p) => !p);
                  }}
                >
                  INR {numberWithCommas(wallet?.cash || 0)}
                  {/* <WalletCard wallet={wallet} /> */}
                </div>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/withdraw");
                }}
              >
                <Button variant="outline-secondary w-100">Withdraw</Button>
              </div>
              <div
                className="col-6"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary w-100">Deposit</Button>
              </div>
            </div>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={ProfileIconNew} alt="ProfileIconNew" />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <img src={MyBetsIconNew} alt="MyBetsIconNew" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus">
                  <img src={BonusIconNew} alt="BonusIconNew" />
                  <span>Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  <img src={RedderalIconNew} alt="RedderalIconNew" />
                  <span>Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={StatementIconNew} alt="StatementIconNew" />
                  <span>Account Statement</span>
                </a>
              </li>

              <li onClick={() => dispatch(logoutUser(true))}>
                <a href="#">
                  <img src={SignOutIconNew} alt="SignOutIconNew" />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
