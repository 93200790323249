import { casinoTabs } from "./casinoTabs.data";

export const EzugiGames = {
  ...casinoTabs.roulettetab,
  ...casinoTabs.baccarattab,
  ...casinoTabs.AndarBahartab,
  ...casinoTabs.TeenPattitab,
  ...casinoTabs.Blackjacktab,
  ...casinoTabs.thirtytwoCardtab,
  ...casinoTabs.sevenLuckytab,
  ...casinoTabs.sicbotab,
  ...casinoTabs.GameShows,
};
